import axios from 'axios';

const clientId = 'Y2969e735-ab2e-43e0-8f6f-dd617a49971d';
const clientSecret = '90f29287-b6e5-4ddf-aa2d-4d2d3351ef0e';
const refreshToken = 'YOUR_REFRESH_TOKEN'; // You will need to handle refresh tokens as well.

export const getAccessToken = async () => {
  const response = await axios.post('https://api.smartthings.com/v1/oauth/token', {
    grant_type: 'refresh_token',
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: refreshToken,
  });
  return response.data.access_token;
};

export const getDevices = async (accessToken) => {
    try {
        console.log('test2');
      
  const response = await axios.get('https://api.smartthings.com/v1/devices', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.items;
} catch (e){
    console.log(e)
   }
};


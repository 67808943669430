import axios from 'axios';
import {getAccessToken} from './Auth';

export const controlTV = async (deviceId, command) => {
    const accessToken = getAccessToken();
  
    await axios.post(`https://api.smartthings.com/v1/devices/${deviceId}/commands`, {
      commands: [
        {
          component: 'main',
          capability: 'switch',
          command: command, // 'on' or 'off'
        },
      ],
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };
  
import React, { useEffect, useState } from 'react';

import {getAccessToken} from './Auth';
import {getDevices} from './Auth';
import {controlTV} from './Control';




const App = () => {
  
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      const accessToken = await  getAccessToken();
      const devices = await getDevices(accessToken);
      setDevices(devices);
    };

    fetchDevices();
  }, []);
  
  const handleControl = async (deviceId, command) => {
    try {
    await controlTV(deviceId, command);
    console.log('test1');
  } catch (e){
    console.log(e)
   }
  };

  return (
    <div>
      <h1>Samsung TV Controller</h1>
      <ul>
        {devices.map(device => (
          <li key={device.deviceId}>
            {device.label}
            <button onClick={() => handleControl(device.deviceId, 'on')}>On</button>
            <button onClick={() => handleControl(device.deviceId, 'off')}>Off</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;

